<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Reporte')+': '+$t('Quejas')}} </h3>
		</div>
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="Nº" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
						<template #body="slotProps">
							{{slotProps.data.codigo}}
						</template>
					</Column>
					<Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.cliente}}
						</template>
					</Column>
                    <Column field="nombrepoliz" :header="$t('Nombre Completo')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.nombrepoliz}}                              
                        </template>
                    </Column>
					<Column field="fecha" :header="$t('Fecha')" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{convfech(slotProps.data.fecha)}}
						</template>
					</Column>
					<Column field="caso" :header="$t('Departamento')" :sortable="true" headerStyle="width: 200px">
						<template #body="slotProps">
							<div v-if="i18n.locale() == 'es'">{{slotProps.data.caso_nombre}}</div>
							<div v-if="i18n.locale() == 'en'">{{slotProps.data.caso_name}}</div>
						</template>
					</Column>
					<Column :header="$t('Estatus')" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.estado">{{$t('Solución')}}</span>
							<span class="p-tag p-tag-danger" v-else>{{$t('Pendiente')}}</span>
						</template>
					</Column>
                    <template #footer footerStyle="text-align:right">
                        <Button icon="pi pi-download" class="p-button-rounded p-button-warning p-mr-2" @click="VerXLS()" v-if="products.length>0"/>
                    </template>
				</DataTable>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import XLS from "../service/XLS";

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				product: {},
				selectedCustomers: null,
				filters: {},
				submitted: false,
				size: '60vw',
				opcion: {code: 0, nombre: 'Todos', name: 'All'},
				opciones: [{code: 0, nombre: 'Todos', name: 'All'}]
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				this.opciones = [{code: 0, nombre: 'Todos', name: 'All'}];
				const Consulta = new API('Reportes');
				Consulta.Ini('Report4').then(result => {
					//this.$store.state.error = result.consult.quejas[0];
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					if (result.consult.asunto){
						result.consult.asunto.forEach(result => {
							this.opciones.push(result);
						});
					}
					if(result.consult.quejas){
						this.products = result.consult.quejas;
					}
					this.$store.commit('Loading');
				}); 
			},
			VerXLS(){
				const xls = new XLS();
				xls.Reporte4(this.products);
			},
			Buscar(){
				this.$store.commit('Loading');
				this.products = [];
				const Consulta = new API('Reportes');
				Consulta.Procesar('Report4',{
					caso: 	'Buscar',
					opcion: this.opcion.code,
					}).then(response => {
						//this.$store.state.error = response.result.quejas[0];	
						if(response.result.quejas){
							this.products = response.result.quejas;
						}
						this.$store.commit('Loading');
					});
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
